.venue-highlights {
  display: grid;
  gap: 1rem;

  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
}

.venue-highlight {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.venue-highlight-header {
  display: flex;
  gap: 0.5rem;
}